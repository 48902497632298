import FormTitle from '~/src/components/utils/form/FormTitle'
import { useTranslations } from '~/src/hooks/useTranslations'

const MarketingPreferencesHeader = () => {
  const { t } = useTranslations()

  return (
    <FormTitle
      title={t('profile.marketing.title')}
      subTitle={t('profile.marketing.description')}
      gridArea='header'
    />
  )
}

export default MarketingPreferencesHeader
