import MarketingPreferences from '~/src/components/profile/marketing-preferences'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'

const Marketing = () => {
  return (
    <ContentContainer>
      <DocumentTitleInjector pageName={'MARKETING'} />
      <MarketingPreferences />
    </ContentContainer>
  )
}

export default Marketing

