import { Customer } from '@eo-storefronts/eo-core'
import { ChangeEvent, useEffect, useState } from 'react'
import useUpdateCustomer from '~/src/hooks/customer/useUpdateCustomer'
import { useEoValue } from '~/src/hooks/useEoState'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import EnvUtils from '~/src/utils/EnvUtils'
import MarketingPreferencesHeader from '~/src/components/profile/marketing-preferences/MarketingPreferencesHeader'
import MarketingPreferencesFields from '~/src/components/profile/marketing-preferences/MarketingPreferencesFields'
import MarketingPreferencesPushNotification
  from '~/src/components/profile/marketing-preferences/MarketingPreferencesPushNotification'
import { Box } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

export interface MarketingPreferencesForm {
  optInEo: boolean,
  optInMerchant: boolean,
}

const MarketingPreferences = () => {
  const customer = useEoValue(CUSTOMER_STATE)
  const theme = useCustomMuiTheme()
  const [ marketingPreferences, setMarketingPreferences ] = useState<MarketingPreferencesForm>({
    optInEo: false,
    optInMerchant: false
  })
  const { update: updateCustomer } = useUpdateCustomer()

  useEffect(() => {
    setMarketingPreferences({
      optInEo: customer?.opt_in_eo ?? false,
      optInMerchant: customer?.opt_in_merchant ?? false
    })
  }, [ customer ])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedMarketingPreferences = {
      ...marketingPreferences,
      [event.target.name]: event.target.checked
    }

    setMarketingPreferences(updatedMarketingPreferences)

    void updateCustomer({
      ...customer,
      opt_in_eo: updatedMarketingPreferences.optInEo,
      opt_in_merchant: updatedMarketingPreferences.optInMerchant
    } as Customer)
  }

  if (!customer) {
    return null
  }

  return (
    <Box sx={{
      display: 'grid',
      gridTemplateAreas: '"header header"' +
        '"promotions promotions-toggle"' +
        '"newsletters newsletters-toggle"' +
        '"push-notifications push-notifications-toggle"',
      gridTemplateColumns: '1fr 1fr',
      rowGap: 5,
      [theme.breakpoints.down('md')]: {
        gridTemplateAreas: '"header"' +
          '"promotions"' +
          '"promotions-toggle"' +
          '"newsletters"' +
          '"newsletters-toggle"' +
          '"push-notifications"' +
          '"push-notifications-toggle"',
        gridTemplateColumns: '1fr',
        rowGap: 2
      }
    }}>
      <MarketingPreferencesHeader />
      <MarketingPreferencesFields
        marketingPreferences={marketingPreferences}
        onChange={handleOnChange}
      />
      {EnvUtils.isDeviceApp && <MarketingPreferencesPushNotification />}
    </Box>
  )
}

export default MarketingPreferences
